[data-appear-chart] {
    height: 360px;

    &[data-appear-chart="eleven_one"] {
        height: 440px;
    }

    &[data-appear-chart="eleven_two"] {
        height: 440px;
    }
    &[data-appear-chart="four"] {
        height: 400px;
    }
}
