/*GRAPH STYLES*/
/*
@font-face {
	src: url(../fonts/CiscoSans-Thin/CiscoSans-Thin.ttf);
	font-family: CiscoSans-Thin;
}
*/
.d-flex{
	display: flex;
	justify-content: space-between;
	/*height: 100%;*/
	/*overflow: hidden;*/
}
.col-2 {
	flex-basis: 16%;
}
.col-3 {
	flex-basis: 24%;
}
.col-4 {
	width: 32%;
}
.col-5 {
	flex-basis: 40%;
}
.col-6 {
	width: 48%;
}
.col-7 {
	flex-basis: 56%;
}
.col-8 {
	flex-basis: 64%;
}
.col-9 {
	flex-basis: 72%;
}
.col-10 {
	flex-basis: 80%;
}
.paragraph {
	color: red;
	font-family: CiscoSans-Thin;
}
#container {
	height: 500px;
	max-width: 1000px;
	margin: 0 auto;
	font-family: CiscoSans;
}
.highcharts-title {
	fill: #434348;
	font-size: 2em;
}
.highcharts-subtitle {
	font-family: CiscoSans;
	fill: #7cb5ec;
}
.highcharts-caption {
	font-family: CiscoSans;
	fill: green;
}