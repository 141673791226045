/* CONTAINERS, BACKGROUNDS, AND EDITS TO TN_APP SELECTOR
====================================================================== */

html {
	height: 102%;
}

.tn_container {
	max-width: 1340px;
	margin: 0 auto;
}

@media (max-width: 1259px) {
	.tn_container {
		padding: 32px;
	}
}

@media (max-width: 1023px) {
	.tn_container {
		padding: 24px;
	}
}
@media (max-width: 767px) {
	.tn_container {
		padding: 16px;
	}
}


/* HEADER - HOME PAGE
====================================================================== */

.tn_blade-home-header {
	background-color: #0d274d;
	background-image: url('../../images/header-background.jpg');
	background-size: 1600px 1000px;
	background-position: center bottom;
	background-repeat: no-repeat;
	position: relative;

	svg {
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 0;
	}
}

.tn_blade-home-header h1 {
	font-size: 6.8rem;
	color: #fff;
}

.tn_blade-home-header p {
	font-size: 2.2rem;
	color: #fff;
}

.tn_home-header {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	max-width: 1340px;
	min-height: 420px;
	margin: 0 auto;
	padding: 40px 40px 0 40px;
}

.tn_home-title {
	flex-basis: 580px;
	padding: 24px 64px 40px 24px;
}

@media (max-width: 1259px) {
	.tn_blade-home-header h1 {
		font-size: 5.6rem;
	}
	.tn_blade-home-header p {
		font-size: 1.8rem;
	}
	.tn_home-header {
		min-height: 380px;
	}
	.tn_home-title {
		padding: 20px 64px 36px 20px;
	}
}

@media (max-width: 1023px) {
	.tn_blade-home-header {
		background-image: url('../../images/header-background-tablet.jpg');
		background-size: 1100px 650px;
		background-position: left top;
	}
	#tn_app-wrapper .tn_blade-home-header h1 {
		font-size: 4.8rem;
		margin-bottom: 0;
	}
	.tn_home-header {
		flex-direction: column;
		min-height: auto;
		padding: 0;
	}
	.tn_home-title {
		flex-basis: auto;
		padding: 36px;
	}
	#tn_app-wrapper .tn_home-title p {
		max-width: 420px;
		margin-top: 0.4em;
	}
}

@media (max-width: 767px) {
	.tn_blade-home-header {
		background-image: none;
	}
	.tn_blade-home-header p {
		font-size: 1.6rem;
	}
	.tn_home-title {
		width: 100%;
		padding: 28px 28px 92px 28px;
		background-color: rgba(255,0,0,0.3);
		background-image: url('../../images/header-background-mobile.jpg');
		background-size: 800px 500px;
		background-position: left bottom;
		background-repeat: no-repeat;
	}
	#tn_app-wrapper .tn_blade-home-header h1 {
		font-size: 3.6rem;
	}
	#tn_app-wrapper .tn_home-title p {
		max-width: 380px;
	}
}

@media (max-width: 419px) {
	.tn_home-title {
		background-size: 700px 438px;
		background-position: -40px bottom;
	}
}

@media (max-width: 339px) {
	.tn_home-title {
		background-size: 600px 375px;
	}
}


/* HEADER - CATEGORY PAGES
====================================================================== */

.tn_blade-category-header {
	text-align: center;
	background-color: #0d274d;
	background-image: url('../../images/header-category-background.jpg');
	background-size: 1600px 1000px;
	background-position: center bottom;
	background-repeat: no-repeat;
}

.tn_category-title {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px;
}

.tn_header-back {
	position: absolute;
	left: 20px;
	flex-basis: 48px;
	flex-shrink: 0;
}

.tn_header-back a {
	display: block;
	width: 48px;
	height: 48px;
	background-image: url('../../images/arrow-left.svg');
	background-size: 48px 48px;
	background-position: center center;
	background-repeat: no-repeat;
}

.tn_header-back a:hover,
.tn_header-back a:active {
	filter: brightness(115%);
}

#tn_app-wrapper .tn_blade-category-header .tn_category-title-copy {
	flex-basis: 100%;
	padding: 0 68px;
}

#tn_app-wrapper .tn_blade-category-header .tn_category-title-copy h1 {
	margin: 0;
	font-size: 4.6rem;
	line-height: 1.3em;
	color: #fff;
}

@media (max-width: 1259px) {
	.tn_category-title {
		padding: 18px;
	}
	#tn_app-wrapper .tn_blade-category-header .tn_category-title-copy h1 {
		font-size: 4.2rem;
	}
}

@media (max-width: 1023px) {
	.tn_category-title {
		padding: 16px;
	}
	.tn_header-back {
		left: 12px;
	}
	#tn_app-wrapper .tn_blade-category-header .tn_category-title-copy {
		padding: 0 56px;
	}
	#tn_app-wrapper .tn_blade-category-header .tn_category-title-copy h1 {
		font-size: 3.8rem;
	}
}

@media (max-width: 767px) {
	.tn_category-title {
		padding: 12px;
	}
	.tn_header-back {
		left: 4px;
	}
	.tn_header-back a {
		background-size: 42px 42px;
	}
	#tn_app-wrapper .tn_blade-category-header .tn_category-title-copy {
		padding: 0 44px;
	}
	#tn_app-wrapper .tn_blade-category-header .tn_category-title-copy h1 {
		font-size: 2.8rem;
	}
}


/* NAV - MAIN MENU
====================================================================== */

.tn_blade-category-header .tn_menu {
	background-color: rgba(0,188,235,0.25);
}

#tn_app-wrapper .tn_menu .tn_container h2 {
	font-size: 3.2rem;
	font-weight: 100;
	line-height: 1.4em;
	text-align: center;
	margin-top: 0;
	margin-bottom: 2.2rem;
	color: #fff;
}

#tn_app-wrapper .tn_menu .tn_menu-array {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

#tn_app-wrapper .tn_menu .tn_menu-array a {
	position: relative;
	display: block;
	width: 100%;
	margin: 0 10px;
	padding: 108px 16px 14px 16px;
	font-size: 1.8rem;
	line-height: 1.4em;
	text-align: center;
	background-color: #eee;
	background-size: 100px 100px;
	background-position: center 8px;
	background-repeat: no-repeat;
	border-radius: 12px;
	//transition: background-color 0.15s ease-in-out;
	transition: opacity 1.2s $easeAppearIn, transform 1s $easeAppearIn, background-color 0.15s ease-in-out;
}

#tn_app-wrapper .tn_menu .tn_menu-array a:first-child {
	margin-left: 0;
}
#tn_app-wrapper .tn_menu .tn_menu-array a:last-child {
	margin-right: 0;
}

#tn_app-wrapper .tn_menu .tn_menu-array a,
#tn_app-wrapper .tn_menu .tn_menu-array a:visited,
#tn_app-wrapper .tn_menu .tn_menu-array a:hover,
#tn_app-wrapper .tn_menu .tn_menu-array a:active {
	color: #1e4471;
	text-decoration: none;
}

#tn_app-wrapper .tn_menu .tn_menu-array a.tn_menu-button-selected,
#tn_app-wrapper .tn_menu .tn_menu-array a.tn_menu-button-selected:visited,
#tn_app-wrapper .tn_menu .tn_menu-array a.tn_menu-button-selected:hover,
#tn_app-wrapper .tn_menu .tn_menu-array a.tn_menu-button-selected:active {
	color: #0d274d;
}

#tn_app-wrapper .tn_menu .tn_menu-array a:hover,
#tn_app-wrapper .tn_menu .tn_menu-array a:active {
	background-color: #fff;
}

#tn_app-wrapper .tn_menu .tn_menu-array a.tn_menu-button-selected:hover,
#tn_app-wrapper .tn_menu .tn_menu-array a.tn_menu-button-selected:active {
	background-color: #4dcff0;
}

#tn_app-wrapper .tn_menu a.tn_menu-button-selected {
	background-color: #00bceb;
}

.tn_button-arrow {
	display: none;
	position: absolute;
	top: 0;
	width: 18px;
	height: 100%;
	background-size: 18px 18px;
	background-position: center center;
	background-repeat: no-repeat;
}

.tn_arrow-down {
	right: 16px;
	background-image: url('../../images/arrow-button-down.svg');
}

.tn_arrow-right {
	right: 12px;
	background-image: url('../../images/arrow-button-right.svg');
}

@media (max-width: 1259px) {
	#tn_app-wrapper .tn_menu .tn_container h2 {
		font-size: 3.0rem;
	}
	#tn_app-wrapper .tn_menu .tn_menu-array a {
		padding: 98px 16px 10px 16px;
		background-size: 90px 90px;
	}
}

@media (max-width: 1023px) {
	#tn_app-wrapper .tn_menu .tn_container h2 {
		font-size: 2.6rem;
	}
	#tn_app-wrapper .tn_menu .tn_menu-array a {
		margin: 0 5px;
		font-size: 1.6rem;
		padding: 84px 16px 8px 16px;
		background-size: 80px 80px;
		background-position: center 6px;
	}
}

@media (max-width: 767px) {
	#tn_app-wrapper .tn_menu .tn_menu-array {
		flex-direction: column;
		justify-content: left;
	}
	#tn_app-wrapper .tn_menu .tn_menu-array a {
		margin: 0 0 8px 0;
		padding: 16px 52px;
		text-align: left;
		background-size: 36px 36px;
		background-position: 8px center;
		border-radius: 8px;
	}
	#tn_app-wrapper .tn_menu .tn_menu-array a:last-child {
		margin-bottom: 0;
	}
	.tn_button-arrow {
		display: block;
	}
}


/* NAV - MAIN MENU - ICONS
====================================================================== */

a.tn_menu-button-apps {
	background-image: url('../../images/nav-apps.svg');
}

a.tn_menu-button-apps.tn_menu-button-selected {
	background-image: url('../../images/nav-apps-selected.svg');
}

a.tn_menu-button-infra {
	background-image: url('../../images/nav-infra.svg');
}

a.tn_menu-button-infra.tn_menu-button-selected {
	background-image: url('../../images/nav-infra-selected.svg');
}

a.tn_menu-button-secure {
	background-image: url('../../images/nav-secure.svg');
}

a.tn_menu-button-secure.tn_menu-button-selected {
	background-image: url('../../images/nav-secure-selected.svg');
}

a.tn_menu-button-work {
	background-image: url('../../images/nav-work.svg');
}

a.tn_menu-button-work.tn_menu-button-selected {
	background-image: url('../../images/nav-work-selected.svg');
}

@media (max-width: 767px) {
	a.tn_menu-button-apps {
	background-image: url('../../images/nav-apps-mobile.svg');
	}
	a.tn_menu-button-apps.tn_menu-button-selected {
		background-image: url('../../images/nav-apps-mobile-selected.svg');
	}
	a.tn_menu-button-infra {
		background-image: url('../../images/nav-infra-mobile.svg');
	}
	a.tn_menu-button-infra.tn_menu-button-selected {
		background-image: url('../../images/nav-infra-mobile-selected.svg');
	}
	a.tn_menu-button-secure {
		background-image: url('../../images/nav-secure-mobile.svg');
	}
	a.tn_menu-button-secure.tn_menu-button-selected {
		background-image: url('../../images/nav-secure-mobile-selected.svg');
	}
	a.tn_menu-button-work {
		background-image: url('../../images/nav-work-mobile.svg');
	}
	a.tn_menu-button-work.tn_menu-button-selected {
		background-image: url('../../images/nav-work-mobile-selected.svg');
	}
}

/* NAV - SIDEBAR - DESKTOP AND MOBILE
====================================================================== */

#tn_app-wrapper .tn_category-sidebar a {
	display: block;
	margin-bottom: 10px;
	padding: 18px 18px 18px 56px;
	font-size: 1.8rem;
	border-radius: 8px;
	background-color: #eee;
	background-size: 40px 40px;
	background-position: 8px center;
	background-repeat: no-repeat;
	transition: background-color 0.15s ease-in-out;
}

#tn_app-wrapper .tn_category-sidebar a:last-child,
#tn_app-wrapper .tn_category-sidebar a:last-child {
	margin-bottom: 0;
}

#tn_app-wrapper .tn_category-sidebar a,
#tn_app-wrapper .tn_category-sidebar a:visited,
#tn_app-wrapper .tn_category-sidebar a:hover,
#tn_app-wrapper .tn_category-sidebar a:active {
	color: #1e4471;
	text-decoration: none;
}

#tn_app-wrapper .tn_category-sidebar a.tn_sidebar-button-selected,
#tn_app-wrapper .tn_category-sidebar a.tn_sidebar-button-selected:visited,
#tn_app-wrapper .tn_category-sidebar a.tn_sidebar-button-selected:hover,
#tn_app-wrapper .tn_category-sidebar a.tn_sidebar-button-selected:active {
	color: #0d274d;
}

#tn_app-wrapper .tn_category-sidebar a:hover,
#tn_app-wrapper .tn_category-sidebar a:active {
	background-color: #f5f5f5;
}

#tn_app-wrapper .tn_category-sidebar a.tn_sidebar-button-selected:hover,
#tn_app-wrapper .tn_category-sidebar a.tn_sidebar-button-selected:active {
	background-color: #4dcff0;
}

#tn_app-wrapper .tn_category-sidebar a.tn_sidebar-button-selected {
	background-color: #00bceb;
}

.tn_sidebar-button-mobile {
	display: none;
	position: relative;
}

@media (max-width: 1259px) {
	#tn_app-wrapper .tn_category-sidebar .tn_sidebar-button-array a {
		font-size: 1.6rem;
		padding: 16px 16px 16px 50px;
		background-size: 36px 36px;
	}
}

@media (max-width: 1023px) {
	#tn_app-wrapper .tn_category-sidebar .tn_sidebar-button-array {
		display: none;
	}
}

@media (max-width: 767px) {
	#tn_app-wrapper .tn_category-sidebar a {
		font-size: 1.6rem;
		padding: 16px 52px;
	}
}

/* NAV - SIDEBAR - DROPDOWN FOR MOBILE
====================================================================== */

.tn_sidebar-dropdown-container {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 500;
	width: 100%;
	padding: 16px;
}

.tn_sidebar-dropdown {
	padding: 8px;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 6px 10px rgba(0,0,0,0.3);
}

#tn_app-wrapper .tn_category-sidebar .tn_sidebar-dropdown a {
	margin-bottom: 8px;
}

#tn_app-wrapper .tn_category-sidebar .tn_sidebar-dropdown a:active {
	background-color: #b1eaf8;
}

#tn_app-wrapper .tn_category-sidebar .tn_sidebar-dropdown a:last-child {
	margin-bottom: 0;
}

@media (max-width: 1023px) {
	.tn_sidebar-button-mobile {
		display: block;
	}
}

@media (max-width: 767px) {
	.tn_sidebar-dropdown-container {
		padding: 8px;
	}
}


/* NAV - SIDEBAR - ICONS
====================================================================== */

.tn_sidebar-button-copy {
	background-image: url('../../images/icon-sidebar-copy.svg');
}

.tn_sidebar-button-copy.tn_sidebar-button-selected {
	background-image: url('../../images/icon-sidebar-copy-selected.svg');
}

.tn_sidebar-button-data {
	background-image: url('../../images/icon-sidebar-data.svg');
}

.tn_sidebar-button-data.tn_sidebar-button-selected {
	background-image: url('../../images/icon-sidebar-data-selected.svg');
}


/* WHAT'S NEW FEATURE
====================================================================== */

#tn_app-wrapper .tn_news-feature {
	flex-basis: 680px;
	width: 680px;
	background-color: rgba(0,0,0,0.5);
	border-radius: 16px;
	padding: 20px 24px;
	color: #fff;
}

#tn_app-wrapper .tn_news-feature h2 {
	margin-bottom: 1.6rem;
	font-size: 2.8rem;
	color: #fff;
}

.tn_news-item {
	.swiper-slide {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #ffffff !important;
		text-decoration: none !important;
	}
}

.tn_news-graphic {
	flex-basis: 220px;
	flex-shrink: 0;
	width: 220px;
	height: 180px;
	overflow: hidden;
	margin-right: 24px;
	border: 1px solid #426288;
	border-radius: 12px;
}

.tn_news-graphic img {
	width: 220px;
	height: 180px;
}

.tn_news-content {
	flex-basis: 388px;
}

.tn_news-date {
	font-size: 1.4rem;
}

.tn_news-headline {
	margin-top: 0.4em;
	font-size: 2.4rem;
	line-height: 1.4em;
}

.tn_news-summary {
	margin-top: 0.4em;
}

@media (max-width: 1259px) {
	#tn_app-wrapper .tn_news-feature {
		padding: 18px 20px;
	}
	#tn_app-wrapper .tn_news-feature h2 {
		font-size: 2.4rem;
	}
	.tn_news-graphic {
		flex-basis: 160px;
		width: 160px;
		height: 130px;
	}
	.tn_news-graphic img {
		width: 160px;
		height: 130px;
	}
	.tn_news-date {
		font-size: 1.3rem;
	}
	.tn_news-headline {
		margin-top: 0.2em;
		font-size: 2.2rem;
	}
}

@media (max-width: 1023px) {
	#tn_app-wrapper .tn_news-feature {
		flex-basis: auto;
		width: calc(100% - 48px);
		margin: 0 24px;
		padding: 20px 24px;
	}
	#tn_app-wrapper .tn_news-feature h2 {
		font-size: 2.8rem;
		margin-bottom: 0.6em;
	}
	.tn_news-content {
		flex-basis: auto;
	}
	.tn_news-graphic {
		flex-basis: auto;
	}
}

@media (max-width: 767px) {
	#tn_app-wrapper .tn_news-feature {
		width: 100%;
		margin: 0;
		padding: 16px 0 12px 0;
		background-color: #1e4471;
		border-radius: 0;
	}
	#tn_app-wrapper .tn_news-feature h2 {
		font-size: 2.4rem;
		text-align: center;
	}
	.tn_news-item {
		.swiper-slide {
			flex-direction: column;
		}
	}
	.tn_news-content {
		width: 100%;
		padding: 0 16px;
	}
	.tn_news-graphic {
		width: 220px;
		height: 180px;
		margin: 0 auto 12px auto;
	}
	.tn_news-graphic img {
		width: 220px;
		height: 180px;
	}
	.tn_news-date {
		font-size: 1.2rem;
	}
	.tn_news-headline {
		margin-top: 0.2em;
		font-size: 2.0rem;
	}
	.tn_news-summary {
		font-size: 1.5rem;
	}
}

/* WHAT'S NEW CAROUSEL
====================================================================== */

.tn_carousel-nav {
	position: relative;
	margin-top: 1.0em;
}

a.tn_carousel-back,
a.tn_carousel-forward {
	position: absolute;
	display: block;
	width: 48px;
	height: 48px;
	background-size: 48px 48px;
	background-position: center center;
	background-repeat: no-repeat;
}

a.tn_carousel-back {
	left: 0;
	background-image: url('../../images/arrow-left.svg');
}

a.tn_carousel-forward {
	right: 0;
	background-image: url('../../images/arrow-right.svg');
}

a.tn_carousel-back:hover,
a.tn_carousel-back:active,
a.tn_carousel-forward:hover,
a.tn_carousel-forward:active {
	filter: brightness(115%);
}

.tn_carousel-pagination {
	height: 48px;
	text-align: center;
	display: flex;
	justify-content: center;
}

.tn_carousel-pagination a {
	display: inline-block;
	width: 48px;
	height: 48px;
	text-align: center;
}

.swiper-pagination-bullet {
	display: block;
	width: 20px;
	height: 20px;
	margin: 14px;
	border-radius: 999px;
	border: 1px solid #00bceb;
	transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;

	&:hover, &:active {
		border-color: #4dcff0;
		background-color: rgba(0,188,235,0.2);
	}

	&.swiper-pagination-bullet-active {
		background-color: #00bceb;
		&:hover, &:active {
			background-color: #4dcff0;
		}
	}
}

@media (max-width: 767px) {
	.tn_carousel-nav {
		margin-top: 0.4em;
	}
	a.tn_carousel-back,
	a.tn_carousel-forward {
		background-size: 42px 42px;
	}
	a.tn_carousel-back {
		left: 2px;
	}
	a.tn_carousel-forward {
		right: 2px;
	}
	.swiper-pagination-bullet {
		width: 16px;
		height: 16px;
		margin: 16px;
	}
}


/* HOME - SECONDARY FEATURE
====================================================================== */

.tn_blade-home-secondary {
	background-color: #1e4471;
}

.tn_secondary {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.tn_secondary-graphic {
	flex: 1;
	height: 360px;
	border-radius: 16px 0 0 16px;
	overflow: hidden;
}

.tn_secondary-graphic img {
	width: 630px;
	height: 360px;
}

.tn_secondary-content {
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 360px;
	background-color: #00bceb;
	border-radius: 0 16px 16px 0;
	overflow: hidden;
	align-items: center;
}

.tn_secondary-copy {
	padding: 40px;
}

.tn_secondary-copy h2 {
	font-size: 3.6rem;
	line-height: 1.4em;
	color: #333;
}

.tn_secondary-copy p {
	font-size: 1.8rem;
	line-height: 1.6em;
	color: #333;
}

@media (max-width: 1259px) {
	.tn_secondary-graphic {
		height: 320px;
	}
	.tn_secondary-content {
		height: 320px;
	}
	.tn_secondary-copy {
		padding: 32px;
	}
	.tn_secondary-copy h2 {
		font-size: 3.2rem;
	}
}

@media (max-width: 1023px) {
	.tn_secondary-graphic {
		height: 300px;
	}
	.tn_secondary-graphic img {
		width: 540px;
		height: 308px;
	}
	.tn_secondary-content {
		height: 300px;
	}
	.tn_secondary-copy {
		padding: 24px;
	}
	.tn_secondary-copy h2 {
		font-size: 2.6rem;
	}
	.tn_secondary-copy p {
		font-size: 1.6rem;
		line-height: 1.4em;
	}
}

@media (max-width: 767px) {
	.tn_secondary {
		flex-direction: column;
	}
	.tn_secondary-graphic {
		flex: auto;
		height: auto;
		max-height: 240px;
		border-radius: 12px 12px 0 0;
	}
	.tn_secondary-graphic img {
		width: 100%;
		height: auto;
	}
	.tn_secondary-content {
		flex: auto;
		height: auto;
		border-radius: 0 0 12px 12px;
	}
	.tn_secondary-copy {
		padding: 16px;
	}
}


/* CATEGORY PAGES
====================================================================== */

.tn_blade-category {
	background-color: #fff;
}

.tn_category {
	display: flex;
	flex-direction: row;
	max-width: 1340px;
	margin: 0 auto;
	padding: 40px;
}

.tn_category-sidebar {
	position: relative;
	flex-basis: 280px;
	flex-shrink: 0;
	margin-right: 40px;
}

.tn_category-article {
	flex-basis: 940px;
}

.tn_article-graphic {
	width: 100%;
	border-radius: 16px 16px 0 0;
	overflow: hidden;
}

.tn_article-graphic img {
	width: 100%;
	height: auto;
}

.tn_article-feature {
	background-color: #f2fcfe;
	margin-bottom: 40px;
	padding: 32px;
	font-size: 1.8rem;
	line-height: 1.6em;
	color: #333;
	border-radius: 0 0 16px 16px;
	overflow: hidden;
}

@media (max-width: 1259px) {
	.tn_category {
		padding: 32px;
	}
	.tn_category-sidebar {
		flex-basis: 240px;
		margin-right: 32px;
	}
	.tn_article-feature {
		margin-bottom: 32px;
		padding: 32px;
	}
}

@media (max-width: 1023px) {
	.tn_blade-category {
		background-color: #d1d2d3;
	}
	.tn_category {
		flex-direction: column;
		padding: 0;
	}
	.tn_category-sidebar {
		flex-basis: auto;
		flex-shrink: auto;
		margin-right: 0;
		padding: 24px;
	}
	.tn_category-article {
		flex-basis: auto;
		background-color: #fff;
		padding: 24px;
	}
	.tn_article-feature {
		margin-bottom: 24px;
		padding: 24px;
		font-size: 1.6rem;
	}
}

@media (max-width: 767px) {
	.tn_category-sidebar {
		padding: 16px;
	}
	.tn_category-article {
		padding: 16px;
	}
	.tn_article-graphic {
		border-radius: 12px 12px 0 0;
	}
	.tn_article-feature {
		padding: 16px;
		border-radius: 0 0 12px 12px;
	}
}

/* INFOGRAPHICS
====================================================================== */

.tn_article-infographic {
	width: 100%;
	margin-top: 40px;
}

.tn_multi-charts {
	display: flex;
	flex-direction: row;
}

.tn_multi-charts > div {
	flex: 1;
	margin-right: 24px;
}

.tn_multi-charts > div:last-child {
	margin-right: 0;
}

.tn_chart-footer {
	padding: 16px;
	font-size: 1.8rem;
	line-height: 1.4em;
	text-align: center;
	border-radius: 12px;
	background-color: #e6e8e8;
}

.tn_article-stat {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #f2fcfe;
	margin: 32px 0 16px 0;
	padding: 24px;
	border-radius: 16px;
	overflow: hidden;
}

.tn_stat-graphic {
	margin-right: 20px;
}

.tn_stat-graphic img {
	width: 120px;
	height: auto;
}

.tn_stat-label {
	font-size: 2.4rem;
	font-weight: 100;
	line-height: 1.4em;
	color: #333;
}

.tn_article-source {
	font-size: 1.4rem;
	line-height: 1.6em;
	color: #777;
	margin-top: -12px;
}

@media (max-width: 1259px) {
	.tn_chart-footer {
		padding: 12px;
		font-size: 1.6rem;
	}
}

@media (max-width: 1023px) {
	.tn_article-infographic {
		margin-top: 24px;
	}
	.tn_article-stat {
		margin: 24px 0 16px 0;
		padding: 16px;
	}
	.tn_stat-label {
		font-size: 2.2rem;
	}
}

@media (max-width: 767px) {
	.tn_multi-charts {
		flex-direction: column;
	}
	.tn_multi-charts > div {
		margin-top: 2.0em;
		margin-right: 0;
	}
	.tn_multi-charts > div:first-child {
		margin-top: 0;
	}
	.tn_article-stat {
		flex-direction: column;
	}
	.tn_stat-graphic {
		margin-right: 0;
		margin-bottom: 8px;
	}
	.tn_stat-label {
		font-size: 2.0rem;
		text-align: center;
	}
	.tn_article-source {
		font-size: 1.2rem;
	}
}


/* CALL TO ACTION
====================================================================== */

.tn_cta {
	background-color: #1e4471;
	color: #fff;
	text-align: center;
}

.tn_cta h2 {
	font-size: 2.4rem;
	line-height: 1.4em;
}

.tn_cta .tn_button-array {
	justify-content: center;
	margin-top: 1.6em;
}

@media (max-width: 767px) {
	.tn_cta {
		text-align: left;
	}
}












