[data-appear-block] {
    > * {
        will-change: opacity, transform;
        backface-visibility: hidden;
        opacity: 0;
        transform: translateY(75px);
        transition: opacity 1.2s $easeAppearIn, transform 1s $easeAppearIn;
    }
    &.is-appeared {
        > * {
            opacity: 1;
            transform: none;
        }
    }
    &[data-appear-block="zoom-in"] {
        > * {
            transform: scale(0.8);
            transition: opacity 1.2s $easeAppearIn, transform 1s $easeAppearIn;
        }
        &.is-appeared {
            > * {
                opacity: 1;
                transform: none;
            }
        }
    }
    &[data-appear-block="from-left"] {
        > * {
            transform: translateX(-75px);
        }
        &.is-appeared {
            > * {
                opacity: 1;
                transform: none;
            }
        }
    }
    &[data-appear-block="from-right"] {
        > * {
            transform: translateX(75px);
        }
        &.is-appeared {
            > * {
                opacity: 1;
                transform: none;
            }
        }
    }
    &[data-appear-block="fade-in"] {
        > * {
            transform: none;
        }
        &.is-appeared {
            > * {
                opacity: 1;
                transform: none;
            }
        }
    }
}

[data-appear-stagger] {
    > * {
        will-change: opacity, transform;
        backface-visibility: hidden;
        opacity: 0;
        transform: translateY(75px);
        transition: opacity 1.2s $easeAppearIn, transform 1s $easeAppearIn;
    }
    &.is-appeared {
        > * {
            opacity: 1;
            transform: none;
        }
    }
    &[data-appear-stagger="zoom-in"] {
        > * {
            transform: scale(0.8);
        }
        &.is-appeared {
            > * {
                opacity: 1;
                transform: none;
            }
        }
    }
    &[data-appear-stagger="from-left"] {
        > * {
            transform: translateX(-75px);
        }
        &.is-appeared {
            > * {
                opacity: 1;
                transform: none;
            }
        }
    }
    &[data-appear-stagger="from-right"] {
        > * {
            transform: translateX(75px);
        }
        &.is-appeared {
            > * {
                opacity: 1;
                transform: none;
            }
        }
    }
    &[data-appear-stagger="fade-in"] {
        > * {
            transform: none;
        }
        &.is-appeared {
            > * {
                opacity: 1;
                transform: none;
            }
        }
    }
}

[data-appear-featured] {
    > .img,
    > .desc > * {
        will-change: opacity, transform;
        backface-visibility: hidden;
        opacity: 0;
        transition: opacity 1.2s $easeAppearIn, transform 1s $easeAppearIn;
    }
    > .desc > * {
        transform: translateY(75px);
        @include media('>=tablet') {
            transform: translateX(75px);
        }
    }
    &.is-appeared {
        > .img,
        > .desc > * {
            opacity: 1;
            transform: none;
        }
    }
    &[data-appear-featured="reverse"] {
        > .desc > * {
            transform: translateY(75px);
            @include media('>=tablet') {
                transform: translateX(-75px);
            }
        }
        &.is-appeared {
            > .desc > * {
                transform: none;
            }
        }
    }
}

[data-appear-item] {
    will-change: opacity, transform;
    backface-visibility: hidden;
    opacity: 0;
    transform: translateY(75px);
    transition: opacity 1.2s $easeAppearIn, transform 1s $easeAppearIn;

    &.is-appeared {
        opacity: 1;
        transform: none;
    }

    &[data-appear-item="zoom-in"] {
        transform: scale(0.8);
        transition: transform 1s $easeAppearIn 0.5s;
        &.is-appeared {
            transform: none;
        }
    }

    &[data-appear-item="from-right"] {
        transform: translateX(75px);

        &.is-appeared {
            opacity: 1;
            transform: none;
        }
    }

    &[data-appear-item="from-left"] {
        transform: translateX(-75px);

        &.is-appeared {
            opacity: 1;
            transform: none;
        }
    }

    &[data-appear-item="fade-in"] {
        transform: none;

        &.is-appeared {
            opacity: 1;
            transform: none;
        }
    }
}

#main-hero-animated {
    .desc {
        > * {
            will-change: transform;
            opacity: 0;
            transform: translateY(-75px);
        }
    }
}

[data-animate-rolling] {
    display: inline-block;
    position: relative;
    vertical-align: top;
    overflow: hidden;
    transition: width 500ms linear;

    > * {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        //width: 100%;
        text-align: center;
        transform: translate3d(0, 100%, 0);
        transition: transform 150ms linear;

        &.visible {
            transform: translate3d(0, 0, 0);
        }

        &.leave {
            transform: translate3d(0, -100%, 0);
        }
    }
}

[data-appear-stagger-content] {
    h1, h2, h3, h4, h5, p, q, blockquote, svg, li {
        will-change: opacity, transform;
        backface-visibility: hidden;
        opacity: 0;
        transform: translateY(75px);
        transition: opacity 1.2s $easeAppearIn, transform 1s $easeAppearIn;
    }
    &.is-appeared {
        h1, h2, h3, h4, h5, p, q, blockquote, svg, li {
            opacity: 1;
            transform: none;
        }
    }
}

.counter-with-x {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 999px;
    background: #00BCEB;

    .counter-value {
        position: absolute;
        color: #1E4471;
        text-align: center;
        left: 5%;
        width: 90%;
        top: 50%;
        font-size: 60px;
        font-weight: 700;
        transform: translate3d(0, -50%, 0);
        line-height: 1;
        letter-spacing: -2px;
    }

    .counter-x {
        display: inline-block;
        position: relative;
        transform: translate3d(20px, 0, 0);
        opacity: 0;
        transition: opacity 1.45s $easeAppearIn, transform 1.2s $easeAppearIn;
    }

    &.is-appeared {
        .counter-x {
            opacity: 1;
            transform: none;
        }
    }

}

.counter-with-arrow {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 999px;
    background: #00BCEB;

    .counter-value {
        position: absolute;
        color: #1E4471;
        text-align: center;
        left: 5%;
        width: 90%;
        top: 35px;
        font-size: 38px;
        font-weight: 100;
    }

    .counter-arrow {
        position: absolute;
        width: 6px;
        height: 30px;
        border-radius: 3px;
        background: #1E4471;
        left: 50%;
        margin-left: -3px;
        bottom: 18px;
        transform: translate3d(0, 40px, 0);
        opacity: 0;
        transition: opacity 1.45s $easeAppearIn, transform 1.2s $easeAppearIn;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 6px;
            height: 22px;
            border-radius: 3px;
            background: #1E4471;
            transform: rotate(45deg);
            transform-origin: 50% 0;
            left: 2px;
            top: 0;
        }

        &:after {
            transform: rotate(-45deg);
            left: -2px;
        }
    }

    &.is-appeared {
        .counter-arrow {
            opacity: 1;
            transform: none;
        }
    }

    &.theme-green {
        background: #6ABF4B;
    }

    &.theme-dark {
        background: #1E4471;

        .counter-value {
            color: #ffffff;
        }

        .counter-arrow {
            background: #ffffff;

            &:after,
            &:before {
                background: #ffffff;
            }
        }
    }
}

[data-counter-circular] {
    position: relative;
    width: 120px;
    height: 120px;

    .donut-text {
        position: absolute;
        top: 52%;
        left: 5%;
        width: 90%;

        color: #00BCEB;
        text-align: center;
        font-size: 38px;
        font-weight: 100;

        transform: translateY(-50%);
    }

    .donut-fill {
        transform-origin: 50%;
        transform: rotateZ(170deg);
    }

    .donut-bg {
        fill: #E4F7FC;
    }

    &[data-counter-circular-theme="green"] {
        .donut-text {
            color: #6ABF4B;
        }

        .donut-bg {
            fill: #EFF8EC;
        }
    }
}
