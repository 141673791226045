/* BOX MODEL, WRAPPER, CLEARFIX, RESET
====================================================================== */

#tn_app-wrapper,
#tn_app-wrapper *,
#tn_app-wrapper *:before,
#tn_app-wrapper *:after {
	box-sizing: border-box;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: auto;
}

#tn_app-wrapper {
	position: relative;
	font-family: 'CiscoSans', Helvetica, Arial, sans-serif;
}

#tn_app-wrapper a {
	font-weight: 400;
}

#tn_app-wrapper img {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: bottom;
}

.tn_clearfix:after {
	content: "";
	display: table;
	clear: both;
}

#tn_app-wrapper p {
	margin: 1.4rem 0 0 0;
}

/*#tn_app-wrapper p:first-child {
	margin-top: 0;
}*/

#tn_app-wrapper h1,
#tn_app-wrapper h2,
#tn_app-wrapper h3,
#tn_app-wrapper h4 {
	margin: 3.2rem 0 1.6rem 0;
}

#tn_app-wrapper h2 {
	margin-top: 0;
}


/*#tn_app-wrapper h1:first-child,
#tn_app-wrapper h2:first-child,
#tn_app-wrapper h3:first-child,
#tn_app-wrapper h4:first-child {
	margin-top: 0;
}*/

ul.tn_content-bullet-list,
ol.tn_content-bullet-list {
	margin: 1.4rem 0 2rem 1.8rem;
}

.tn_content-bullet-list li + ul,
.tn_content-bullet-list li + ol {
	margin: 0 0 0 2.2rem;
}

.tn_content-bullet-list li {
	margin: 0.9rem 0 0 0;
}

ul.tn_content-bullet-list {
	list-style-type: disc;
}

ul.tn_content-bullet-list li + ul {
	list-style-type: circle;
}

ol.tn_content-bullet-list {
	list-style-type: decimal;
}

ol.tn_content-bullet-list li + ol {
	list-style-type: lower-alpha;
}


/* CONTAINERS AND BACKGROUNDS
====================================================================== */

.tn_container {
	padding: 40px;
}
@media (max-width: 1023px) {
	.tn_container {
		padding: 24px;
	}
}
@media (max-width: 767px) {
	.tn_container {
		padding: 16px;
	}
}

.tn_container.tn_tab-container {
	padding: 40px 40px 0 40px;
}
@media (max-width: 1023px) {
	.tn_container.tn_tab-container {
		padding: 24px 24px 0 24px;
	}
}
@media (max-width: 767px) {
	.tn_container.tn_tab-container {
		padding: 16px;
	}
}

.tn_background-lt-gray {
	background-color: #f2f2f2;
}

.tn_background-lt-blue {
	background-color: #f2fcfe;
}

.tn_background-dk-gray {
	background-color: #4d4c4c;
}


/* BUTTON ARRAY
====================================================================== */

.tn_button-array {
	display: flex;
	flex-direction: row;
/*	margin-top: 1.2rem;*/
}
@media (max-width: 767px) {
	.tn_button-array {
		flex-direction: column;
	}
}

.tn_button-array li {
	margin: 0;
}

.tn_button-array li + li {
	margin-left: 12px;
}
@media (max-width: 767px) {
	.tn_button-array li {
		width: 100%;
		margin-bottom: 12px;
		text-align: center;
	}
	.tn_button-array li + li {
		margin-left: 0;
	}
	.tn_button-array li a.tn_button-capsule:link,
	.tn_button-array li a.tn_button-capsule:visited,
	.tn_button-array li a.tn_button-capsule:hover,
	.tn_button-array li a.tn_button-capsule:active {
		width: 288px;
	}
}


/* CAPSULE BUTTONS
====================================================================== */

a.tn_button-capsule:link,
a.tn_button-capsule:visited,
a.tn_button-capsule:hover,
a.tn_button-capsule:active {
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
	display: inline-block;
	min-width: 140px;
	padding: 11px 24px 12px 24px;
	text-align: center;
	text-decoration: none;
	line-height: 1.0em;
	vertical-align: top;
	border-radius: 999px;
}

a.tn_button-capsule.tn_capsule-primary:link,
a.tn_button-capsule.tn_capsule-primary:visited {
	background-color: #1e4471;
	color: #fff;
	border: 1px solid #1e4471;
}

a.tn_button-capsule.tn_capsule-primary:hover,
a.tn_button-capsule.tn_capsule-primary:active {
	background-color: #4c5e84;
	border-color: #4c5e84;
}

a.tn_button-capsule.tn_capsule-secondary:link,
a.tn_button-capsule.tn_capsule-secondary:visited {
	background-color: #fff;
	color: #0175a2;
	border: 1px solid #0175a2;
}

a.tn_button-capsule.tn_capsule-secondary:hover,
a.tn_button-capsule.tn_capsule-secondary:active {
	background-color: #0d274d;
	color: #fff;
	border-color: #0d274d;
}

a.tn_button-capsule.tn_capsule-alternate:link,
a.tn_button-capsule.tn_capsule-alternate:visited {
	background-color: #00bceb;
	color: #0d274d;
	border: 1px solid #00bceb;
}

a.tn_button-capsule.tn_capsule-alternate:hover,
a.tn_button-capsule.tn_capsule-alternate:active {
	background-color: #4dcff0;
	border-color: #4dcff0;
}


/* RECTANGULAR BUTTONS
====================================================================== */

a.tn_button-rect:link,
a.tn_button-rect:visited,
a.tn_button-rect:hover,
a.tn_button-rect:active {
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
	display: inline-block;
	min-width: 140px;
	padding: 14px 24px 15px 24px;
	text-align: center;
	text-decoration: none;
	line-height: 1.0em;
	vertical-align: top;
	border-radius: 8px;
}

a.tn_button-rect:link,
a.tn_button-rect:visited {
	background-color: #1e4471;
	color: #fff;
}

a.tn_button-rect:hover,
a.tn_button-rect:active {
	background-color: #4c5e84;
	border-color: #4c5e84;
}


/* TAB ARRAY
====================================================================== */

.tn_tab-array {
	display: flex;
	flex-direction: row;
	justify-content: left;
/*	margin-top: 1.2rem;*/
}
@media (max-width: 767px) {
	.tn_tab-array {
		flex-direction: column;
	}
}

.tn_tab-array li {
	margin-bottom: 0;
	text-align: center;
}

.tn_tab-array li + li {
	margin-left: 12px;
}
@media (max-width: 767px) {
	.tn_tab-array li + li {
		margin-left: 0;
		margin-top: 12px;
	}
}


/* TABS
====================================================================== */

a.tn_tab:link,
a.tn_tab:visited,
a.tn_tab:hover,
a.tn_tab:active {
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
	display: inline-block;
	min-width: 140px;
	padding: 14px 24px 15px 24px;
	text-align: center;
	text-decoration: none;
	line-height: 1.0em;
	vertical-align: top;
	border-radius: 8px 8px 0 0;
}
@media (max-width: 767px) {
	a.tn_tab:link,
	a.tn_tab:visited,
	a.tn_tab:hover,
	a.tn_tab:active {
		width: 288px;
		border-radius: 8px;
	}
}

a.tn_tab:link,
a.tn_tab:visited {
	background-color: #e5e5e5;
	color: #0d274d;
}

a.tn_tab:hover,
a.tn_tab:active {
}

a.tn_tab.tn_tab-selected:link,
a.tn_tab.tn_tab-selected:visited {
	background-color: #fff;
}

a.tn_tab.tn_tab-selected:hover,
a.tn_tab.tn_tab-selected:active {
}


/* CALL-TO-ACTION LINKS
====================================================================== */

a.tn_cta-link {
	font-size: 18px;
}

a.tn_cta-link:hover,
a.tn_cta-link:active {
	text-decoration: none;
	border-bottom: 1px solid;
}

a.tn_cta-link:after {
    border-right: solid 2px;
    border-top: solid 2px;
    content: '';
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    display: inline-block;
    position: relative;
    right: -6px;
    margin-right: -8px;
    top: -2px;
}









